import {
  QForm,
  QInput,
  QBtn,
} from '@quasar/components';
import { required, confirmed } from '@vjs/validations';
import VFormInform from '@vjs/components/VFormInform/VFormInform';

export default {
  name: 'VPasswordChange',
  components: {
    QForm,
    QInput,
    QBtn,
    VFormInform,
  },
  props: {
    action: {
      type: String,
      default: '',
    },
    routeBack: {
      type: String,
      default: '',
    },
  },
  data() {
    /* this.trans не успевает объявиться для использования в data,
    *  поэтому создаём его локально */
    const trans = this.$store.getters['translator/trans'];

    return {
      ratingModel: 3,
      fields: [{
        value: '',
        name: 'cur_password',
        required: true,
        rules: [
          required(trans('validation.required', {
            attribute: trans('label.current_password'),
          })),
        ],
      },
      {
        value: '',
        name: 'new_password',
        required: true,
        rules: [
          required(trans('validation.required', {
            attribute: trans('label.new_password'),
          })),
        ],
      },
      {
        value: '',
        name: 'new_password_confirmation',
        required: true,
        rules: [
          required(trans('validation.required', {
            attribute: trans('label.replay_new_password'),
          })),
        ],
      }],
    };
  },
  beforeCreate() {
    this.$trans.add(['validation', 'label', 'button', 'warning_text']);
  },
  created() {
    this.fields[2].rules.push(
      confirmed(this.fields[1], this.trans('validation.confirmed', {
        attribute: this.trans('label.new_password'),
      })),
    );
  },
  methods: {
  },
};
