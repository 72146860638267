function pushNotify(obj = {}, type = 'error') {
  Object.values(obj).forEach((val) => {
    this.$notify({
      type,
      title: type === 'error' ? window.L.error : window.L.message,
      text: val,
    });
  });
}

export default pushNotify;
