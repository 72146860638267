import axios from 'axios';
import { FALLBACK_LOCALE } from '@vjs/balabaqsha/consts';

export default class JournalConfirmRepository {
  constructor({
    apiUrl,
    locale,
  }) {
    this.url = apiUrl || '';
    this.locale = locale || FALLBACK_LOCALE;
  }

  async confirm(groupId, date) {
    const { url, locale } = this;
    // eslint-disable-next-line no-return-await
    return await axios.post(
      url,
      {
        groupId,
        date,
      },
      {
        headers: { 'X-localization': locale },
      },
    );
  }
}
