export default class FinalFundingListProvider {
  // eslint-disable-next-line max-len
  constructor({
    EntityListDateFetchRepository,
    FinalListFundingCalcRepository,
    EntityListDateCancelRepository,
    EntityListDateConfirmRepository,
  }, trans) {
    this.fundingFetchRepository = EntityListDateFetchRepository;
    this.fundingCalcRepository = FinalListFundingCalcRepository;
    this.fundingCancelRepository = EntityListDateCancelRepository;
    this.fundingConfirmRepository = EntityListDateConfirmRepository;
    this.trans = trans;
  }

  provideFetch() {
    const { fundingFetchRepository, trans } = this;
    // eslint-disable-next-line func-names
    return async function (localityId, date, type) {
      try {
        const response = await fundingFetchRepository
          .get({
            id: localityId,
            date,
            type,
          });
        const {
          status,
          message,
        } = response.data;

        if (status !== 'ok') {
          this.$notify({
            type: 'warn',
            title: message,
          });
        } else {
          return response.data;
        }
      } catch (error) {
        const message = error.response && error.response.message
          ? error.response.message
          : trans('notice.error_on_server');

        this.$notify({
          type: 'error',
          text: message,
        });
      }

      return [];
    };
  }

  provideCalculate() {
    const { fundingCalcRepository } = this;
    // eslint-disable-next-line func-names
    return async function (localityId, date, type) {
      try {
        const response = await fundingCalcRepository
          .send({
            id: localityId,
            date,
            type,
          });
        const {
          status,
          message,
        } = response.data;

        if (status !== 'ok') {
          this.$notify({
            type: 'warn',
            text: message,
          });
        } else {
          return response.data;
        }
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'server error',
          text: error,
        });
      }

      return null;
    };
  }

  provideCancel() {
    const { fundingCancelRepository } = this;
    // eslint-disable-next-line func-names
    return async function (id) {
      try {
        const response = await fundingCancelRepository
          .send({ id });
        const {
          status,
          message,
        } = response.data;

        if (status !== 'ok') {
          this.$notify({
            type: 'warn',
            text: message,
          });
        } else {
          return response.data;
        }
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'server error',
          text: error,
        });
      }

      return null;
    };
  }

  provideConfirm() {
    const { fundingConfirmRepository } = this;
    // eslint-disable-next-line func-names
    return async function (id) {
      try {
        const response = await fundingConfirmRepository
          .send({ id });
        const {
          status,
          message,
        } = response.data;

        if (status !== 'ok') {
          this.$notify({
            type: 'warn',
            title: message,
          });
        } else {
          return response.data;
        }
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'server error',
          text: error,
        });
      }

      return null;
    };
  }
}
