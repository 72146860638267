<template>
  <q-dialog
    :value="modal"
    @input="handleModal"
  >
    <v-modal
      :title="trans('label.remove_priority_status')"
      size="medium"
    >
      <div class="mb-30">
        {{ trans('label.remove_priority_status_confirm') }}
      </div>
      <div class="row justify-content-between">
        <div class="col col-sm-auto sn--text-center mt-5 mb-5">
          <q-btn
            v-close-popup
            width="20"
            :label="trans('button.cancel')"
            label-wider
            color="grey"
          />
        </div>
        <div class="col col-sm-auto sn--text-center mt-5 mb-5">
          <q-btn
            width="20"
            :label="trans('button.approve')"
            color="green"
            @click="handleConfirm"
          />
        </div>
      </div>
    </v-modal>
  </q-dialog>
</template>

<script>
import { QBtn, QDialog } from '@quasar/components';
import VModal from '@vjs/components/VModal';
import { ClosePopup } from '@quasar/directives';

export default {
  name: 'VApplicationRemovePriorityModal',
  components: {
    QDialog,
    QBtn,
    VModal,
  },
  directives: { ClosePopup },
  props: {
    modal: {
      type: Boolean,
      default: () => false,
    },
  },
  async mounted() {
    await this.$trans.add([
      'label',
      'button',
    ]);
  },
  methods: {
    handleModal() {
      this.$emit('modal-close', false);
    },
    handleConfirm() {
      this.$emit('modal-confirm');
    },
  },
};
</script>

<style scoped>

</style>
