import {
  requestWrapper,
} from '@vjs/helpers';
import _ from 'lodash';
import VFormInform from '@vjs/components/VFormInform';
import httpCodes from '@vjs/config/httpCodes';
import {
  QForm,
  QIcon,
  QPopupProxy,
  QDate,
  QInput,
  QBtn,
  QSelect,
  QField,
  QRadio,
  QOptionGroup,
} from '@quasar/components';
import { date } from '@quasar/utils';
import Validation from '@vjs/mixins/Validation';
import { scrollTop } from '../../balabaqsha/helpers';

export default {
  mixins: [Validation],

  components: {
    VFormInform,
    QForm,
    QIcon,
    QPopupProxy,
    QDate,
    QInput,
    QBtn,
    QSelect,
    QField,
    QRadio,
    QOptionGroup,
    date,
  },
  props: {
    urls: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      loading: false,
      action: true,
      user: {
        iin: '',
        user_id: 0,
        loaded: false,
        lastname: '',
        firstname: '',
        surname: '',
        born_date: '',
        email: '',
        phone: '',
        nationality_id: 0,
        sex: 'm',
        country_name: '',
        resident: true,
        migration_status: 0,
      },
      personnel: {
        loaded: false,
        personnel_id: 0,
        education_kind: '',
        defectologist_edu: 0,
        academic_degree: '',
        academic_category: '',
        pedagogical_experience: '',
        english_level: '',
        qual_training_passed: 0,
        ikt_training_passed: 0,
      },
      personnel_movement: {
        position: 0,
        hiring_date: '',
        hiring_order_number: '',
        both_loaded: false,
      },
      options: {
        nationalities: [],
        nationalitiesFiltered: [],
        positionsSorted: [],
        positionsFiltered: [],
        positions: [],
        migrationStatuses: [],
        educationKinds: [],
        academicDegrees: [],
        academicCategories: [],
        englishLevels: [],
      },
      selected: {
        academic_category: '',
        academic_degree: '',
        education_kind: '',
        english_level: '',
        position: '',
        migration_status: '',
        nationality_id: '',
      },
      step: 1,
      disableIin: false,
      genderOptions: [
        {
          value: 'm',
          label: 'label.male',
        },
        {
          value: 'w',
          label: 'label.female',
        },
      ],
      yesNoOptions: [
        {
          value: 0,
          label: 'button.no',
        },
        {
          value: 1,
          label: 'button.yes',
        },
      ],
    };
  },
  async mounted() {
    this.$trans.add([
      'label',
      'warning_text',
      'placeholder',
      'fields',
      'button',
      'validation',
      'notice',
    ]);
    // get select options from apis
    _.forIn(this.options, (value, key) => {
      this.getOptions(key);
    });
  },
  computed: {
    hasValidationFail() {
      const { validation } = this;
      return field => Array.isArray(validation[field]) && validation[field].length > 0;
    },
    validate() {
      if (!this.user.loaded && !this.personnel.loaded) {
        return this.selected.position !== ''
        && this.personnel_movement.hiring_date !== ''
        && this.personnel_movement.hiring_order_number !== '';
      }

      if (!this.user.loaded) {
        return this.selected.education_kind !== ''
          && this.selected.academic_category !== ''
          && this.selected.position !== ''
          && this.personnel.pedagogical_experience !== ''
          && this.personnel_movement.hiring_date !== ''
          && this.personnel_movement.hiring_order_number !== '';
      }

      return this.user.firstname !== null
        && this.user.surname !== ''
        && this.validateIin(this.user.iin)
        && this.user.born_date !== ''
        && this.user.phone !== ''
        && this.selected.education_kind !== ''
        && this.selected.academic_category !== ''
        && this.selected.position !== ''
        && this.personnel.pedagogical_experience !== ''
        && this.personnel_movement.hiring_date !== ''
        && this.personnel_movement.hiring_order_number !== '';
    },
  },
  methods: {
    filterNationality(val, update) {
      const { nationalities } = this.options;

      if (val === '') {
        update(() => {
          this.options.nationalitiesFiltered = nationalities;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.options.nationalitiesFiltered = nationalities.filter(v => v.label.toLowerCase().indexOf(needle) > -1);
      });
    },
    filterPosition(val, update) {
      const { positions } = this.options;

      if (val === '') {
        update(() => {
          this.options.positionsFiltered = positions;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.options.positionsFiltered = positions.filter(v => v.label.toLowerCase().indexOf(needle) > -1);
      });
    },
    cancel() {
      this.action = true;
      this.disableIin = false;
      this.user.loaded = false;
      this.user.user_id = 0;
      this.user.iin = '';
      this.personnel.loaded = false;
      this.personnel.personnel_id = 0;
      this.personnel_movement.both_loaded = false;
      this.step -= 1;
    },
    fill() {
      this.action = false;
      this.disableIin = false;
      this.user.loaded = true;
      this.personnel.loaded = true;
      this.personnel.personnel_id = 0;
      this.personnel_movement.both_loaded = true;
      this.step += 1;
    },
    handleSuccess(response) {
      if (this.step === 1) {
        this.disableIin = true;
        this.action = false;
        _.forIn(this.user, (value, key) => {
          if (response[key] !== null) {
            this.user[key] = response[key];
          } else {
            this.user[key] = '';
          }
        });
        this.user.resident = true;
        this.user.born_date = date.formatDate(this.user.born_date * 1000, 'DD.MM.YYYY');
        if (response.personal) {
          this.personnel.loaded = false;
          _.forIn(this.personnel, (value, key) => {
            if (response.personal[key] !== null) {
              if (key === 'personnel_id') {
                this.personnel.personnel_id = response.personal.id;
              } else {
                this.personnel[key] = response.personal[key];
              }
            } else {
              this.personnel[key] = '';
            }
          });
        } else {
          this.personnel.loaded = true;
        }
        this.personnel_movement.both_loaded = true;
        this.step += 1;
      } else if (this.step === 2) {
        window.location.href = response.redirectUrl;
      }
    },
    async getOptions(arg) {
      const result = await requestWrapper.call(this, {
        method: 'get',
        url: this.urls[arg],
        headers: { 'X-localization': window.core_project.locale },
      });

      if (result.error) {
        return false;
      }

      this.options[arg] = result.data;
      this.options.positions = this.optgroupsOut(this.options.positionsSorted);
      return true;
    },
    async sendRequest() {
      this.loading = true;
      if (this.step === 1) {
        const result = await requestWrapper.call(this, {
          url: `/personnel/user/${this.user.iin}`,
          data: {
            user: {
              iin: this.user.iin,
            },
          },
        });

        if (!result.error) {
          this.handleSuccess(result);
        }

        this.loading = false;
      } else {
        this.personnel.academic_category = this.selected.academic_category.code;
        this.personnel.academic_degree = this.selected.academic_degree.code;
        this.personnel.education_kind = this.selected.education_kind.code;
        this.personnel.english_level = this.selected.english_level.code;
        this.personnel_movement.position = this.selected.position.code;
        this.user.migration_status = this.selected.migration_status.code;
        this.user.nationality_id = this.selected.nationality_id.code;
        const result = await requestWrapper.call(this, {
          method: 'post',
          url: this.urls.submit,
          data:
            {
              user: this.user,
              personnel: this.personnel,
              personnel_movement: this.personnel_movement,
            },
        });

        if (result.error) {
          if (result.errorType === httpCodes.HTTP_UNPROCESSABLE_ENTITY) {
            if (result.data.errors?.['personnel_movement.position']) {
              this.$notify({
                type: 'error',
                text: result.data.errors?.['personnel_movement.position']?.[0],
              });
            }

            if (!result.data.errors?.['personnel_movement.position'] || Object.keys(result.data.errors).length > 1) {
              this.$notify({
                type: 'error',
                text: this.trans('notice.check_data'),
              });

              scrollTop(
                window,
                window.scrollY,
                window.scrollY - Math.abs(this.$refs.form.$el.getBoundingClientRect().top),
                500,
              );
            }

            this.loading = false;
            return false;
          }
        }

        this.loading = false;
        return true;
      }
    },
    optgroupsOut(arg) {
      let selectOptions = [];
      arg.forEach((item) => {
        selectOptions = _.concat(selectOptions, item.children);
      });
      return selectOptions;
    },
    validateInput(str) {
      return (val) => {
        const chunks = str.split('.');
        chunks.reduce((acc, itm, idx, arr) => {
          if (idx < arr.length - 1) {
            return acc[itm];
          }
          acc[itm] = val.replace(/[^a-zA-Zа-яА-ЯәӘғҒқҚңҢөӨұҰүҮhҺіІ\- ]/g, '');
        }, this);
      };
    },
  },
};
