import _ from 'lodash';

const Validation = {
  data: () => ({
    validation: {
      group_id: [],
      age: [],
      lang: [],
      type: [],
      budget: [],
      count: [],
      date_start: [],
      date_active: [],
      date_end: [],
      correction: [],
      sanatorium: [],
    },
    sanatoriumType: null,
  }),
  computed: {
    getValidationMessage() {
      const { validation, hasValidationFail } = this;
      return field => (hasValidationFail(field)
        ? _.head(validation[field])
        : '');
    },
  },
  methods: {
    hasValidationFail(field) {
      const { validation } = this;
      return Array.isArray(validation[field]) && validation[field].length > 0;
    },
    addValidationError(field, value) {
      this.validation[field].push(value);
    },
    validate() {
      let valid = true;
      const {
        addValidationError,
        selectedGroup,
        selectedTypeId,
        correction,
        sanatorium,
        sanatoriumType,
        disease,
      } = this;

      if (selectedGroup === undefined) {
        addValidationError(
          'group_id',
          this.$trans.get('placeholder.freeplace_name'),
        );
        valid = false;
      }

      if (correction) {
        if (
          !selectedGroup.diseases
          || !selectedGroup.diseases.length > 0
        ) {
          addValidationError(
            'correction',
            this.$trans.get('bulletin.empty-group-disease'),
          );
          valid = false;
        } else if (!disease) {
          addValidationError(
            'correction',
            this.$trans.get('bulletin.select-disease'),
          );
          valid = false;
        }
      }
      if (sanatorium) {
        if (
          !selectedGroup.sanatorium
          || !selectedGroup.sanatorium.length > 0
        ) {
          addValidationError(
            'sanatorium',
            this.$trans.get('bulletin.empty-group-sanatorium'),
          );
          valid = false;
        } else if (!sanatoriumType) {
          addValidationError(
            'sanatorium',
            this.$trans.get('bulletin.select-sanatorium'),
          );
          this.$notify({
            text: this.$trans.get('bulletin.select-sanatorium'),
            type: 'error',
          });
          valid = false;
        }
      }

      if (!selectedTypeId) {
        addValidationError(
          'type',
          this.$trans.get('placeholder.freeplace_type'),
        );
        valid = false;
      }
      return valid;
    },
  },
};

export default Validation;
