import {
  QForm,
  QOptionGroup,
  QBtn,
} from '@quasar/components';
import api from '@vjs/helpers/api';

export default {
  name: 'VProfileSettings',
  components: { QForm, QOptionGroup, QBtn },
  props: {
    fields: {
      type: Object,
      default: () => ({
        lang: {
          group: '',
          options: [{
            label: '',
            value: '',
          }],
        },
      }),
    },
    urls: {
      type: Object,
      default: () => ({
        formAction: '',
      }),
    },
  },

  data() {
    return {
      input: '',
      dataFields: {},
    };
  },

  methods: {
    async send() {
      const data = new FormData();
      data.append('lang', this.dataFields.lang.group);
      api(this.urls.formAction, data);
    },
  },

  beforeCreate() {
    this.$trans.add(['label', 'validation', 'fields', 'button']);
  },

  created() {
    this.dataFields = this.fields;
  },
};
