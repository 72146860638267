import {
  requestWrapper,
} from '@vjs/helpers';
import {
  QDialog,
  QBtn,
} from '@quasar/components';
import VModal from '@vjs/components/VModal';

export default {
  name: 'WarningRolesModal',
  components: {
    QDialog,
    QBtn,
    VModal,
  },
  props: {
    type: String,
    linkNotify: String,
    promoText: String,
  },
  created() {
    this.$trans.add(['label', 'warning_text', 'main']);
  },
  data() {
    return {
      modalWarningRoles: false,
    };
  },
  mounted() {
    if (this.type === 'user') {
      this.modalWarningRoles = true;
    }
  },
  methods: {
    async onModalClose() {
      await requestWrapper({
        method: 'POST',
        url: this.linkNotify,
      });
    },
  },
};
