import {
  QField,
  QSelect,
} from '@quasar/components';
import VSelectFromListOption from '../VSelectFromListOption';

export default {
  name: 'VSelectFromList',

  components: {
    QSelect,
    QField,
    VSelectFromListOption,
  },

  props: {
    label: String,
    selectPlaceholder: String,
    selected: Array,
    options: Array,
    required: Boolean,
  },

  data() {
    return {
      selectedOption: '',
      selectedList: [],
      propsSelectedOnce: false,
    };
  },

  watch: {
    // Читаем пропс если запуск в режиме редактирования
    selected(val, oldVal) {
      if (
        !this.propsSelectedOnce
        && val && val !== oldVal && Array.isArray(val) && val.length > 0
      ) {
        this.propsSelectedOnce = true;
        this.options.forEach((item) => {
          if (
            this.selected.includes(item.value)
            && !this.selectedList.map(item => item.value).includes(item.value)
          ) {
            this.selectedList.push(item);
          }
        });
      }
    },
    selectedList() {
      this.$emit('onchange', this.selectedList);
    },
  },

  computed: {
    optionsList() {
      return this.options.filter(item => !this.selectedList.find(itm => itm.value === item.value));
    },
  },

  methods: {
    handleSelect(val) {
      this.selectedList.push(val);
      this.selectedOption = '';
    },
    handleRemoveOption(id) {
      this.selectedList = this.selectedList.filter(item => item.value !== id);
    },
  },
};
