import AttendanceGroupList from './VAttendanceGroupList.vue';
import JournalView from './VJournalView.vue';
import JournalViewExtended from './VJournalViewExtended.vue';
import TimeSheetView from './VTimeSheetView.vue';

export const VAttendanceGroupList = AttendanceGroupList;
export const VJournalView = JournalView;
export const VJournalViewExtended = JournalViewExtended;
export const VTimeSheetView = TimeSheetView;

export default {
  VAttendanceGroupList,
  VJournalView,
  VTimeSheetView,
  VJournalViewExtended,
};
