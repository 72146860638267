<template>
  <div>
    <v-funding-view
      :data="{context: calculation}"
      :calculable="calculable"
      :listable="listRatios"
    />
  </div>
</template>

<script>
import Localization from '../Mixins/QuasarLocale';
import VFundingView from './Components/VFundingView.vue';

export default {
  name: 'VFundingCalculationView',
  components: { VFundingView },
  mixins: [Localization],
  props: {
    dataConfig: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    // eslint-disable-next-line camelcase
    const { core_project } = window;
    const locale = core_project.locale || 'kz';
    const {
      dataConfig: {
        calculation,
        calculable,
        listRatios,
        groupName,
      },
    } = this;

    return {
      locale,
      calculable,
      calculation,
      listRatios,
      groupName,
    };
  },
  beforeMount() {
    this.initLocale();
  },
};
</script>

<style scoped>

</style>
