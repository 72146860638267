import { requestWrapper } from '@vjs/helpers';

export default {
  name: 'VPageContent',
  props: {
    uriContent: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      content: '',
    };
  },

  async mounted() {
    const contentRes = await requestWrapper.call(this, {
      url: this.uriContent,
    });

    if (!contentRes.error) {
      this.content = (contentRes.data && contentRes.data.body) || '';
    }
  },

  render(h) {
    return h('div', {
      staticClass: 's-plain-text',
      domProps: {
        innerHTML: this.content,
      },
    });
  },
};
