export const cn = (className, defaultClass = '') => {
  if (typeof className === 'string') {
    return `${className} ${defaultClass}`.trim();
  }
  if (typeof className === 'object') {
    const computed = Object
      .keys(className)
      .map(key => (className[key] ? `${key}` : ''))
      .join(' ');
    return `${computed} ${defaultClass}`.trim();
  }
  return `${defaultClass}`.trim();
};

export default { cn };
