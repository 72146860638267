import axios from 'axios';
import { FALLBACK_LOCALE } from '@vjs/balabaqsha/consts';

export default class FundingFetchRepository {
  constructor({ apiUrl, locale }) {
    this.apiUrl = apiUrl || '';
    this.locale = locale || FALLBACK_LOCALE;
  }

  async get(groupId) {
    // eslint-disable-next-line no-return-await
    return await axios.get(
      // eslint-disable-next-line camelcase
      `${this.apiUrl}/${groupId}`,
      {
        headers: { 'X-localization': this.locale },
      },
    );
  }
}
