// Mixins
import Vue from 'vue';
import Translator from './Translator';
import StoreTranslator from './StoreTranslator';
import FormField from './FormField';
import Form from './Form';

const eventBus = new Vue();
export {
  // Mixins
  Form,
  FormField,
  Translator,
  StoreTranslator,
  eventBus,
};
