<template>
  <div class="s-vue-modal__body">
    <p class="s-medium">
      {{ trans('label.docs_criteries') }}:
    </p>
    <ul class="mb-30">
      <li>{{ trans('label.file_formats') }};</li>
      <li>{{ trans('label.file_size') }};</li>
      <li>{{ trans('label.file_dimensions') }};</li>
    </ul>
    <div class="row justify-content-between">
      <div class="col-auto">
        <button
          class="s-btn s-btn--w-200 s-btn--thm-grey"
          @click="close"
        >
          <span class="s-str-ico s-str-ico--thm-angle-left">
            {{ trans('button.cancel') }}
          </span>
        </button>
      </div>
      <div class="col-auto">
        <button
          class="s-btn s-btn--w-200 s-btn--thm-green"
          @click="fileUpload"
        >
          <span class="s-str-ico s-str-ico--thm-export">
            {{ trans('button.review') }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import eventHub from '@vjs/config/eventHub';

export default {
  name: 'FileUpload',
  methods: {
    close() {
      this.$modal.close();
    },
    fileUpload() {
      eventHub.$emit('fileUpload');
    },
  },
};
</script>
