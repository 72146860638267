<template>
  <div>
    <h4 class="group-list-header">
      {{ trans('funding.kindergarten.header', { kindergarten }) }}
    </h4>
    <q-table
      :data="funding"
      :columns="columns"
      separator="cell"
      flat
    >
      <template v-slot:header="props">
        <q-tr :props="props">
          <q-th
            v-for="col in props.cols"
            :key="col.name"
            :props="props"
            class="funding-table-header"
          >
            {{ col.label }}
          </q-th>
        </q-tr>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props">
          <!--suppress JSUnresolvedVariable -->
          <q-td
            key="name"
            class="funding-table-cell"
            :props="props"
          >
            {{ props.row.name }}
          </q-td>
          <!--suppress JSUnresolvedVariable -->
          <q-td
            key="group_children_count"
            class="funding-table-cell"
            :props="props"
          >
            {{
              props.row.group_children_count && props.row.group_children_count
                ? props.row.group_children_count
                : 0
            }}
          </q-td>
          <!--suppress JSUnresolvedVariable -->
          <q-td
            key="plan"
            class="funding-table-cell"
            :props="props"
          >
            {{
              props.row.plan && props.row.plan
                ? formatPrice(props.row.plan)
                : 0
            }}
          </q-td>
          <!--suppress JSUnresolvedVariable -->
          <q-td
            key="fact"
            class="funding-table-cell"
            :props="props"
          >
            {{
              props.row.fact && props.row.fact
                ? formatPrice(props.row.fact)
                : 0
            }}
          </q-td>
        </q-tr>
      </template>
      <template v-slot:bottom-row="props">
        <tr>
          <!--suppress JSUnresolvedVariable -->
          <th :colspan="columns.length">
            {{ trans('label.total') }}
          </th>
        </tr>
        <tr>
          <th>{{ trans('funding.amount-fact-date', {date}) }}</th>
          <!--suppress JSUnresolvedVariable, JSUnresolvedFunction -->
          <td :colspan="columns.length -1">
            <span class="s-medium">{{ formatPrice(total) }}</span>
          </td>
        </tr>
      </template>
    </q-table>
  </div>
</template>

<script>
// noinspection NpmUsedModulesInstalled
import {
  FUNDING_KINDERGARTEN_TRANSLATIONS,
} from '@vjs/balabaqsha/consts';
import {
  QTable,
  QTr,
  QTd,
  QTh,
} from '@quasar/components';
import Localization from '../Mixins/QuasarLocale';
import PriceFormat from '../Mixins/PriceFormat';

// noinspection NpmUsedModulesInstalled

export default {
  name: 'VFundingKindergartenGroupListFinal',
  components: {
    QTable,
    QTr,
    QTd,
    QTh,
  },
  mixins: [Localization, PriceFormat],
  props: {
    dataConfig: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    // eslint-disable-next-line camelcase
    const { core_project } = window;
    const locale = core_project.locale || 'kz';
    // eslint-disable-next-line camelcase
    const {
      dataConfig: {
        kindergarten, funding, date, total,
      },
    } = this;

    return {
      kindergarten,
      funding,
      locale,
      date,
      total,
    };
  },
  computed: {
    columns() {
      const { $trans: { get: trans }, date } = this;
      return [
        {
          name: 'name',
          field: 'name',
          label: trans('fields.group_name'),
        },
        {
          name: 'group_children_count',
          field: 'group_children_count',
          label: trans('funding.kindergarten.table.funding-count'),
        },
        {
          name: 'plan',
          field: 'plan',
          label: trans('funding.group.table.funding-plan-date', { date }),
        },
        {
          name: 'fact',
          field: 'fact',
          label: trans('funding.group.table.funding-fact-date', { date }),
        },
      ];
    },
  },
  beforeCreate() {
    this.$trans.add(FUNDING_KINDERGARTEN_TRANSLATIONS);
  },
  beforeMount() {
    this.initLocale();
  },

};
</script>

<style scoped>
.group-list-header {
  margin: 0 0 1em;
}
</style>
