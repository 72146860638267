import eventHub from '@vjs/config/eventHub';

export default {
  install(Vue) {
    Vue.prototype.$modal = new Vue({
      name: 'Modals',

      created() {
        eventHub.$on('opened', (data) => {
          this.$emit('modals:opened', data);
        });

        eventHub.$on('closed', (data) => {
          this.$emit('modals:closed', data);
        });

        eventHub.$on('destroyed', (data) => {
          this.$emit('modals:destroyed', data);
        });

        this.$on('new', (options) => {
          this.open(options);
        });

        this.$on('close', (data) => {
          this.close(data);
        });

        this.$on('dismiss', (index) => {
          this.dismiss(index || null);
        });
      },

      methods: {
        open(options = null) {
          eventHub.$emit('new', options);
        },

        close(data = null) {
          eventHub.$emit('close', data);
        },

        dismiss(index = null) {
          eventHub.$emit('dismiss', index);
        },
      },
    });

    // Mixer for components
    Vue.mixin({
      created() {
        this.$on('modals:new', (options) => {
          eventHub.$emit('new', options);
        });

        this.$on('modals:close', (data) => {
          eventHub.$emit('close', data);
        });

        this.$on('modals:dismiss', (index) => {
          eventHub.$emit('dismiss', index);
        });
      },
    });
  },
};
