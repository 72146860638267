<template>
  <tr
    ref="rowElement"
    :class="rowClass"
    :style="rowHeightStyle"
  >
    <slot />
  </tr>
</template>

<script>
import { cn } from '../../helpers/support';

export default {
  name: 'VTsRow',
  props: {
    className: {
      type: [String, Object],
      default: () => '',
    },
    height: {
      type: Number,
      default: () => null,
    },
  },
  computed: {
    rowClass() {
      const { className } = this;
      return cn(className, 's-time-sheet-row');
    },
    rowHeightStyle() {
      const { height } = this;
      if (height) {
        return `height: ${height}px`;
      }
      return height;
    },
  },
  methods: {
    getRow() {
      return this.$refs.rowElement;
    },
  },
};
</script>

<style scoped>

</style>
