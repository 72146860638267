export default {
  props: {
    departmentName: {
      type: String,
      default: '',
    },
    childCount: {
      type: Number,
      default: 0,
    },
    organizationCount: {
      type: Number,
      default: 0,
    },
    applicationCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      childCountVal: this.childCount > 400 ? this.childCount - 400 : 0,
      organizationCountVal: this.organizationCount > 400 ? this.organizationCount - 400 : 0,
      applicationCountVal: this.applicationCount > 400 ? this.applicationCount - 400 : 0,
    };
  },
  methods: {
    onScroll(e) {
      if ((window.innerHeight + window.pageYOffset) >= this.$el.offsetTop) {
        this.removeScrollHandler();


        if (this.childCount !== 0) {
          const intervalСhildCount = setInterval(() => {
            if (++this.childCountVal >= this.childCount) {
              clearInterval(intervalСhildCount);
            }
          }, 1500 / this.childCount);
        }

        if (this.organizationCount !== 0) {
          const intervalOrganizationCount = setInterval(() => {
            if (++this.organizationCountVal >= this.organizationCount) {
              clearInterval(intervalOrganizationCount);
            }
          }, 1500 / this.organizationCount);
        }
        if (this.applicationCount !== 0) {
          const intervalApplicationCount = setInterval(() => {
            if (++this.applicationCountVal >= this.applicationCount) {
              clearInterval(intervalApplicationCount);
            }
          }, 1500 / this.applicationCount);
        }
      }
    },
    removeScrollHandler() {
      window.removeEventListener('scroll', this.onScroll);
    },
    formatNumberValue(number) {
      let formattedNumber = number;
      if (typeof number !== 'number') {
        formattedNumber = Number(number);
      }

      // eslint-disable-next-line no-restricted-globals
      if (isNaN(formattedNumber)) {
        formattedNumber = 0;
      }

      return new Intl.NumberFormat('ru-RU', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(formattedNumber);
    },
  },
  async mounted() {
    window.addEventListener('scroll', this.onScroll);
    this.$on('hook:beforeDestroy', this.removeScrollHandler);
    this.onScroll();
    await this.$trans.add([
      'label', 'main',
    ]);
  },
};
