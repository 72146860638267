export default {
  name: 'VSelectFromListOption',

  props: {
    labelLeft: String,
    labelRight: String,
    value: String,
    index: Number,
    keyId: [String, Number],
    placeholder: String,
    readonly: Boolean,
  },

  methods: {
    input(val) {
      this.$emit('input', val.target.value);
    },
    handleRemove() {
      this.$emit('remove', this.keyId || this.index);
    },
  },
};
