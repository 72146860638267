export default {
  props: {
    value: {},
    field: {
      type: Object,
      required: true,
    },
    fieldName: {
      type: String,
    },
    errors: {
      type: [Object, Array],
      default() {
        return [];
      },
    },
  },

  data: () => ({
    localValue: '',
  }),

  watch: {
    localValue(value) {
      // update value
      this.fieldEmitInput(value);
    },
    value(value) {
      // update value
      this.setInitialValue();
    },
  },

  mounted() {
    this.setInitialValue();
  },

  destroyed() {
  },

  methods: {
    setInitialValue() {
      this.localValue = !(this.field.value === undefined || this.field.value === null)
        ? this.field.value
        : '';
    },
    fieldEmitInput(value) {
      this.$emit('input', value);
    },
  },

  computed: {},
};
