<template>
  <q-dialog
    :value="modal"
    @input="handleModal"
  >
    <v-modal
      :title="trans('notice.confirm')"
      size="medium"
    >
      <div class="s-modal__body">
        <div class="mb-30">
          {{ text }}
        </div>
        <div class="row-sort-between-btn">
          <q-btn
            :label="trans('button.cancel')"
            class="s-btn--w-200"
            color="grey"
            type="button"
            @click="handleModal"
          />
          <q-btn
            :label="trans('button.approve')"
            class="s-btn--w-200"
            color="green"
            type="button"
            @click="send"
          />
        </div>
      </div>
    </v-modal>
  </q-dialog>
</template>

<script>
import {
  QDialog,
  QBtn,
} from '@quasar/components';
import {
  requestWrapper,
} from '@vjs/helpers';
import VModal from '@vjs/components/VModal';

export default {
  name: 'VUserProfileModal',
  components: {
    QDialog,
    QBtn,
    VModal,
  },
  props: {
    modal: Boolean,
    text: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    userIin: {
      type: String,
      required: true,
    },
    userDocumentNumber: {
      type: String,
      default: null,
    },
    userId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {

    };
  },
  beforeCreate() {
    this.$trans.add(['label', 'send', 'notice']);
  },
  methods: {
    handleModal() {
      this.$emit('modal-close', false);
    },
    async send() {
      this.loading = true;
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.action,
        data: {
          iin: this.userIin,
          document_number: this.userDocumentNumber,
          user_id: this.userId,
        },
      });

      if (res && !res.error && res.redirect) {
        window.location.href = res.redirect;
      } else {
        this.loading = false;
        this.handleModal();
      }
    },
  },
};
</script>
