<template>
  <div class="s-vue-modal__body">
    <div class="mb-30">
      {{ trans('notice.confirm') }}
    </div>
    <div class="row justify-content-between">
      <div class="col-auto">
        <button
          class="s-btn s-btn--w-200 s-btn--thm-grey"
          @click="close"
        >
          {{ trans('button.cancel') }}
        </button>
      </div>
      <div class="col-auto">
        <button
          class="s-btn s-btn--w-200 s-btn--thm-green"
          @click="removeFile"
        >
          {{ trans('button.approve') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import eventHub from '@vjs/config/eventHub';

export default {
  name: 'FileRemoval',
  methods: {
    close() {
      this.$modal.close();
    },
    removeFile() {
      eventHub.$emit('fileRemoval');
    },
  },
};
</script>
