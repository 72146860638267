import axios from 'axios';
import { FALLBACK_LOCALE } from '@vjs/balabaqsha/consts';

export default class listLocalitiesByDepartment {
  constructor({ apiUrl, locale }) {
    this.apiUrl = apiUrl || '';
    this.locale = locale || FALLBACK_LOCALE;
  }

  async listLocalitiesByDepartment(departmentId, currentDate) {
    // eslint-disable-next-line no-return-await
    return await axios.get(
      `${this.apiUrl}/${departmentId}`,
      {
        headers: { 'X-localization': this.locale },
        params: {
          date: currentDate,
        },
      },
    );
  }
}
