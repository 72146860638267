<template>
  <form
    v-cloak
    class="row-sort-wrap"
    method="get"
  >
    <q-select
      v-model="form.source"
      class="mb-20"
      :label="trans('label.select_source')"
      :options="options"
      emit-value
      map-options
      @input="submit"
    />
    <div class="row align-items-end mb-10">
      <div class="col-sm-5 col-md-4 col-lg-3">
        <label class="s-form__label">{{ trans('label.born_date') }}:</label>
        <q-input
          v-model="form.bday_from"
          filled
          mask="##.##.####"
          :label="trans('label.period_from')"
          :placeholder="trans('placeholder.freeplace_date_active')"
          @click="() => $refs.bday_from.show()"
          @input="submit"
        >
          <template v-slot:append>
            <q-icon
              name="calendar"
              class="sn--cursor-pointer"
            >
              <q-popup-proxy
                ref="bday_from"
                transition-show="scale"
                transition-hide="scale"
              >
                <!--suppress JSUnresolvedVariable -->
                <q-date
                  v-model="form.bday_from"
                  mask="DD.MM.YYYY"
                  color="green"
                  @input="() => {
                    $refs.bday_from.hide();
                    submit();
                  }"
                />
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </div>

      <div class="col-sm-5 col-md-4 col-lg-3">
        <q-input
          v-model="form.bday_to"
          filled
          mask="##.##.####"
          :label="trans('label.period_till')"
          :placeholder="trans('placeholder.freeplace_date_active')"
          @click="() => $refs.bday_to.show()"
          @input="submit"
        >
          <template v-slot:append>
            <q-icon
              name="calendar"
              class="sn--cursor-pointer"
            >
              <q-popup-proxy
                ref="bday_to"
                transition-show="scale"
                transition-hide="scale"
              >
                <!--suppress JSUnresolvedVariable -->
                <q-date
                  v-model="form.bday_to"
                  mask="DD.MM.YYYY"
                  color="green"
                  @input="() => {
                    $refs.bday_to.hide();
                    submit();
                  }"
                />
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </div>
    </div>

    <div class="row align-items-end mb-10">
      <div class="col-sm-5 col-md-4 col-lg-3">
        <label class="s-form__label">{{ trans('label.choice_date') }}:</label>
        <q-input
          v-model="form.period_from"
          filled
          mask="##.##.####"
          :label="trans('label.period_from')"
          :placeholder="trans('placeholder.freeplace_date_active')"
          @click="() => $refs.period_from.show()"
          @input="submit"
        >
          <template v-slot:append>
            <q-icon
              name="calendar"
              class="sn--cursor-pointer"
            >
              <q-popup-proxy
                ref="period_from"
                transition-show="scale"
                transition-hide="scale"
              >
                <!--suppress JSUnresolvedVariable -->
                <q-date
                  v-model="form.period_from"
                  mask="DD.MM.YYYY"
                  color="green"
                  @input="() => {
                    $refs.period_from.hide();
                    submit();
                  }"
                />
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </div>

      <div class="col-sm-5 col-md-4 col-lg-3">
        <q-input
          v-model="form.period_to"
          filled
          mask="##.##.####"
          :label="trans('label.period_till')"
          :placeholder="trans('placeholder.freeplace_date_active')"
          @click="() => $refs.period_to.show()"
          @input="submit"
        >
          <template v-slot:append>
            <q-icon
              name="calendar"
              class="sn--cursor-pointer"
            >
              <q-popup-proxy
                ref="period_to"
                transition-show="scale"
                transition-hide="scale"
              >
                <!--suppress JSUnresolvedVariable -->
                <q-date
                  v-model="form.period_to"
                  mask="DD.MM.YYYY"
                  color="green"
                  @input="() => {
                    $refs.period_to.hide();
                    submit();
                  }"
                />
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </div>
    </div>
  </form>
</template>

<script>
import vSelect from 'vue-select';
import DatePicker from 'vue2-datepicker';
import axios from 'axios';
import pushNotify from '@vjs/helpers/pushNotify';
import url from '@vjs/helpers/url';
import {
  QSelect,
  QDate,
  QInput,
  QPopupProxy,
  QIcon,
} from '@quasar/components';

export default {
  name: 'VMonitoringBad',
  components: {
    DatePicker,
    QSelect,
    QDate,
    QInput,
    QPopupProxy,
    QIcon,
  },
  props: {
    sourceTypes: {
      type: Object,
      default: () => ({
        all: 'all',
        portal: 'portal',
        import: 'import',
      }),
    },
    fieldsData: {
      type: Object,
      default: () => ({}),
    },
    urls: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    form: {
      source: {
        code: 'all',
        label: 'Все',
      },
      period_from: '',
      period_to: '',
      bday_from: '',
      bday_to: '',
    },
    lang: window.core_project.locale,
    preloader: `<div class="preloader-wrapper" id="preloader">
                  <svg class="svg" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
                    <circle class="path" stroke-width="10" stroke-miterlimit="10" class="cls-1 path" cx="100" cy="100" r="94"/>
                  </svg>
                </div>`,
  }),
  computed: {
    dynahref() {
      const vm = this;
      const strUrl = `${url('/control/monitoring/export')}?${this.serialize(vm.form)}`;
      return strUrl;
    },
    options() {
      return [{
        value: 'all',
        label: this.trans('main.all'),
      },
      {
        value: 'portal',
        label: this.trans('menu.accepted_kids_portal'),
      },
      {
        value: 'import',
        label: this.trans('label.import'),
      }];
    },
  },
  beforeCreate() {
    this.$trans.add(['label', 'menu', 'main', 'placeholder']);
  },
  created() {
    this.form = { ...this.form, ...this.fieldsData };
  },
  methods: {
    async submit() {
      try {
        const elResultStatistics = document.getElementById('result-statistics');
        elResultStatistics.insertAdjacentHTML('afterbegin', this.preloader);
        const res = await axios({
          method: 'GET',
          url: this.urls.route,
          params: this.form,
        });
        const { data } = res;
        elResultStatistics.innerHTML = data;
      } catch (err) {
        if (err.response && err.response.data && err.response.data.errors) {
          pushNotify(err.response.data.errors);
        } else {
          this.$notify({
            type: 'error',
            title: window.L.error,
            text: err.response.data.message,
          });
        }
      } finally {
        const preloader = document.querySelector('#preloader');
        if (preloader) {
          preloader.remove();
        }
      }
    },
    serialize(form) {
      const serialized = [];
      Object.keys(form).forEach((key) => {
        serialized.push(`${encodeURIComponent(key)}=${encodeURIComponent(form[key])}`);
      });
      return serialized.join('&');
    },
    async exportToExcel() {
      try {
        const res = await axios({
          method: 'GET',
          url: this.urls.export,
          params: this.form,
        });
      } catch (err) {
        if (err.response && err.response.data && err.response.data.errors) {
          pushNotify(err.response.data.errors);
        } else {
          this.$notify({
            type: 'error',
            title: window.L.error,
            text: err.response.data.message,
          });
        }
      }
    },
  },
};
</script>
