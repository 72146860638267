import {
  QBtn,
} from '@quasar/components';

export default {
  name: 'VStepCongratulations',

  components: {
    QBtn,
  },

  props: {
    uriStatic: {
      type: String,
      default: '',
    },
    linkStatements: {
      type: String,
      default: '',
    },
  },

  beforeCreate() {
    this.$trans.add(['button', 'notifications']);
  },
};
