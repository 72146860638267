export default class FormApiProvider {
  constructor({ freePlaceRepository, groupLanguageRepository }) {
    this.freePlaceRepository = freePlaceRepository;
    this.groupLanguageRepository = groupLanguageRepository;
  }

  provideGetGroupLanguageByGroupId() {
    const { groupLanguageRepository } = this;
    // eslint-disable-next-line func-names
    return async function () {
      const {
        trans,
        selectedGroupId: groupId,
        groupTranslations,
      } = this;
      this.groupTranslation = {};
      if (groupTranslations[groupId] === undefined) {
        if (groupId) {
          try {
            const response = await groupLanguageRepository.get(groupId);
            const { data: { group } } = response;

            group.age = group.age || trans('notice.group_age_not_specified');

            this.groupTranslation = group;
            this.groupTranslations[groupId] = group;
          } catch (error) {
            this.$notify({
              type: 'error',
              text: error.response.data.message,
            });
          }
        }
      } else {
        this.groupTranslation = groupTranslations[groupId];
      }
    };
  }

  provideCreateFreePlace() {
    const { freePlaceRepository } = this;
    // eslint-disable-next-line func-names
    return async function (data) {
      try {
        const response = await freePlaceRepository.store(data);
        const {
          validation,
          status,
          redirect,
          fail,
          process,
        } = response.data;

        if (validation) {
          this.validation = validation;
        } else {
          // eslint-disable-next-line no-unused-expressions
          status === 'ok' && redirect
            ? window.location.href = redirect
            : this.$notify({
              type: 'error',
              title: fail,
              text: `process ${process} failed`,
            });
        }
      } catch (e) {
        this.$notify({
          type: 'error',
          title: 'server error',
          text: e,
        });
      }
    };
  }

  provideUpdateFreePlace(id) {
    const { freePlaceRepository } = this;
    // eslint-disable-next-line func-names
    return async function (data) {
      try {
        const response = await freePlaceRepository.update(data, id);
        const {
          validation,
          status,
          redirect,
          fail,
          process,
        } = response.data;

        if (validation) {
          this.validation = validation;
        } else {
          // eslint-disable-next-line no-unused-expressions
          status === 'ok' && redirect
            ? window.location.href = redirect
            : this.$notify({
              type: 'error',
              title: fail,
              text: `process ${process} failed`,
            });
        }
      } catch (e) {
        this.$notify({
          type: 'error',
          title: 'server error',
          text: e,
        });
      }
    };
  }
}
