import _ from 'lodash';

export default {
  name: 'VSteps',

  beforeCreate() {
    this.$trans.add('statement');
  },

  render(h) {
    const {
      steps,
      currentStep,
    } = this.$store.state.vSteps;
    const items = [];
    let numForSteps = 1;

    _.forEach(steps, (val) => {
      if (val.visible) {
        items.push(h('li', {
          key: numForSteps,
          staticClass: 's-steps-numeration__item',
          class: {
            'is-active': currentStep === val.step,
            'is-success': currentStep > val.step,
          },
          attrs: {
            'data-numeration': numForSteps,
          },
        }, this.trans(val.label)));
        numForSteps += 1;
      }
    });

    return h('ul', {
      staticClass: 's-steps-numeration',
    }, items);
  },
};
