// noinspection NpmUsedModulesInstalled
import localeKz from '@locale/kz';
// noinspection NpmUsedModulesInstalled
import localeRu from '@locale/ru';
// noinspection NpmUsedModulesInstalled
import localeEn from '@locale/en-us';

const locales = {
  kz: localeKz,
  ru: localeRu,
  en: localeEn,
  default: localeKz,
};

const Localization = {
  methods: {
    initLocale() {
      let locale = locales.default;

      if (locales[this.locale] !== undefined) {
        locale = locales[this.locale];
      }

      this.$q.lang.set(locale);
    },
  },
};

export default Localization;
