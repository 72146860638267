import axios from 'axios';
import { FALLBACK_LOCALE } from '@vjs/balabaqsha/consts';

export default class GroupLanguageRepository {
  constructor({ apiUrl, locale }) {
    this.apiUrl = apiUrl || '';
    this.locale = locale || FALLBACK_LOCALE;
  }

  async get(groupId) {
    // eslint-disable-next-line no-return-await
    return await axios.post(
      this.apiUrl,
      {
        group: groupId,
      },
      {
        headers: { 'X-localization': this.locale },
      },
    );
  }
}
