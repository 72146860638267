import axios from 'axios';
import { FALLBACK_LOCALE } from '@vjs/balabaqsha/consts';

export default class KindergartensRepository {
  constructor({ apiUrl, locale }) {
    this.apiUrl = apiUrl || '';
    this.locale = locale || FALLBACK_LOCALE;
  }

  async listKindergartensByLocalityId(localityId, date) {
    // eslint-disable-next-line no-return-await
    return await axios.get(
      `${this.apiUrl}/${localityId}`,
      {
        headers: { 'X-localization': this.locale },
        params: {
          date,
        },
      },
    );
  }
}
