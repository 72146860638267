import axios from 'axios';
import { FALLBACK_LOCALE } from '@vjs/balabaqsha/consts';

export default class FinalListFundingCalcRepository {
  constructor({ apiUrl, locale }) {
    this.apiUrl = apiUrl || '';
    this.locale = locale || FALLBACK_LOCALE;
  }

  async send({ id, date, type }) {
    // eslint-disable-next-line no-return-await
    return await axios.post(
      `${this.apiUrl}/${id}`,
      {
        date,
        type,
      },
      {
        headers: { 'X-localization': this.locale },
      },
    );
  }
}
